import { Controller } from "stimulus"
import * as echarts from 'echarts'
//import ruRectSVG from '../ru-rect.svg.geo'
import $ from "jquery"


export default class extends Controller {
  connect() {
    let svg = this.data.get('svg') || ''
    let info = JSON.parse(this.data.get('info')) || []
    let palette = JSON.parse(this.data.get('colors')) || undefined
    let title = this.data.get('title') || ''
    let legendTitle = this.data.get('legendTitle') || ''
    let type = this.data.get('type') || ''
    let containerDom = this.element

    let minValue = Number.MAX_VALUE
    let maxValue = 1
    info.forEach((el) => {
      if (el.value > maxValue) {
        maxValue = el.value
      }
      if (el.value < minValue) {
        minValue = el.value
      }
    })

    echarts.registerMap('RUrect', {svg: svg})

    let map = 'RUrect'

    setTimeout(() => {
      this.chart = echarts.init(containerDom, null, { renderer: 'canvas' });
      // this.chart.on('rendered', function () {
      //   $("svg g g").children('text').each(function() {
      //     let text_el = $(this)
      //     $.each(info, function(index, i) {
      //       if(text_el.text() === 'code_' + i.code) {
      //         text_el.text(i.value)
      //       }
      //     });
      //   })
      // });
    }, 75)

    let opts = {
      title: {
        text: title,
        left: 'center',
      },
      toolbox: {
        feature: {
          saveAsImage : {
            show: true,
            type: 'png',
            backgroundColor: 'black'
          }
        }
      },
      tooltip: {
        trigger: 'item',
        showDelay: 0,
        transitionDuration: 0.2,
        formatter: function (el) {
          let value = 0
          let vfd = 0
          let csm = 0
          let dep = 0
          let cab = 0
          if (type === 'filling'){
            value = echarts.format.encodeHTML(el.data.tooltip_value);
            return [
              '<div class="tooltip-title">' +
              echarts.format.encodeHTML(el.data.name) +
              ': <strong>' + value + '</div>',

            ].join('');
          }else if(type === 'regions'){
            value = el.value;
            let vfd = echarts.format.encodeHTML(el.data.medical_gymnasium_dispensary);
            let csm = echarts.format.encodeHTML(el.data.sport_medicine_center);
            let dep = echarts.format.encodeHTML(el.data.sport_medicine_department);
            let cab = echarts.format.encodeHTML(el.data.sport_medicine_cabinet);
            return [
              '<div class="tooltip-title">' +
              echarts.format.encodeHTML(el.data.name) +
              ': <strong>' + value + '</strong><br>ВФД: <strong>' +
              vfd + '</strong><br>ЦСМ: <strong>' +
              csm + '</strong><br>Отделение: <strong>' +
              dep + '</strong><br>Кабинет: <strong>' +
              cab + '</strong></div>',

            ].join('');
          }

        }
      },
      visualMap: {
        left: 'center',
        orient: 'horizontal',
        text: ['', legendTitle],
        min: 0,
        max: maxValue,
        inRange: {
          color: palette,
          0 : 'red'
        },
        //text: ['High', 'Low'],
        calculable: true
      },
      series: [
        {
          name: '',
          type: 'map',
          map,
          //roam: true, //zoom+pan
          emphasis: {
            label: {
              show: false
            }
          },
          select: {
            label: {
              show: false
            }
          },
          data: info
        }
      ]
    };

    setTimeout(() => {
      this.chart.setOption(opts)
    }, 75)
    setTimeout(() => {
      const img = new Image();
      img.src = this.chart.getDataURL({
        type: 'png',
        pixelRatio: 1.2,
        backgroundColor: '#fff',
        excludeComponents: ['toolbox']
      });
      $("#charts_image_data").val(img.src)
    }, 100);
  }

  disconnect() {
    this.chart.dispose()
  }

  layout() {
    this.chart.resize()
  }
}
