import { Controller } from "stimulus"
import $ from "jquery"

export default class extends Controller {
  connect() {
    this.toggleVocRegion(this.data.has('role') && this.data.get('role') === '4')
    this.toggleVocZone(this.data.has('role') && this.data.get('role') === '3')
    this.toggleOrg(this.data.has('role') && this.data.get('role') === '5')
  }

  doRoleSelect(event) {
    const roleId = event.target.value
    this.toggleVocRegion(
      roleId === '4'
    )
    this.toggleVocZone(
      roleId === '3'
    )
    this.toggleOrg(
      roleId === '5'
    )
  }

  toggleVocRegion(isReg) {
    if (isReg) {
      $('#voc_region').show()
    } else {
      $('#voc_region').hide()
    }
  }
  toggleVocZone(isFed) {
    if (isFed) {
      $('#voc_zone').show()
    } else {
      $('#voc_zone').hide()
    }
  }
  toggleOrg(isOrg) {
    if (isOrg) {
      $('#organization').show()
    } else {
      $('#organization').hide()
    }
  }
}
