import { Controller } from "stimulus"
import * as echarts from 'echarts'
import $ from "jquery"

export default class extends Controller {
  connect() {
    let info = JSON.parse(this.data.get('info')) || []
    let labels_info = JSON.parse(this.data.get('labels_info')) || []
    let title = this.data.get("title") || ''
    let imageField = this.data.get("imageField") || ''

    setTimeout(() => {
      this.chart = echarts.init(this.element,null, { renderer: 'canvas' })
    }, 75)

    let opts = {
      title: {
        text: title,
        left: 'center',
        textStyle: {
          fontSize: 14,
        },
      },
      tooltip: {
        //trigger: 'axis',
        axisPointer: {
          type: 'shadow'
        }
      },
      toolbox: {
        feature: {
          saveAsImage : {
            show: true,
            type: 'png',
          }
        }
      },
      xAxis: {
        type: 'category',
        data: labels_info,
        axisLabel: {
          //inside: true,
          interval: info.length > 80 ? 1 : 0,
          fontSize: 10,
          rotate: 30,
          axisTick: {
            show: false
          },
          axisLine: {
            show: false
          },
        }
      },
      yAxis: {
        type: 'value'
      },

      series: [
        {
          data: info,
          type: 'bar',
        }
      ]
    };

    setTimeout(() => {
      this.chart.setOption(opts)
    }, 75);
    setTimeout(() => {
      const img = new Image();
      img.src = this.chart.getDataURL({
        type: 'png',
        pixelRatio: 1.1,
        backgroundColor: '#fff',
        excludeComponents: ['toolbox']
      });
      $('#' + imageField).val(img.src)
    }, 2000);
  }

  setImageData(chart) {

  }

  disconnect() {
    this.chart.dispose()
  }

  layout() {
    this.chart.resize()
  }
}
