import { Controller } from "stimulus"
import $ from 'jquery'

export default class extends Controller {
  static targets = [ "type", "autocompletePath", "selectFieldTypeGroup", "selectFieldType", "selectVoc", "vocValues" ]

  connect() {
    this.toggleAutocomplete(this.typeTarget.value == "autocomplete")
    this.toggleSelectFieldType(this.typeTarget.value == "select")
    this.toggleSelectVoc(this.selectFieldTypeTarget.value == "voc")
    this.toggleVocValues(this.selectFieldTypeTarget.value == "values")
  }

  doTypeSelect(event) {
    const type = event.target.value
    this.toggleAutocomplete(type == "autocomplete")
    this.toggleSelectFieldType(type == "select")
  }

  doSelectFieldTypeSelect(event) {
    const type = event.target.value
    this.toggleSelectVoc(type == "voc")
    this.toggleVocValues(type == "values")
  }

  toggleAutocomplete(isAc) {
    if (isAc) {
      $(this.autocompletePathTarget).show()
    } else {
      $(this.autocompletePathTarget).hide()
    }
  }

  toggleSelectFieldType(isSelect) {
    if (isSelect) {
      $(this.selectFieldTypeGroupTarget).show()
    } else {
      $(this.selectFieldTypeGroupTarget).hide()
    }
  }

  toggleSelectVoc(isSelect) {
    if (isSelect) {
      $(this.selectVocTarget).show()
    } else {
      $(this.selectVocTarget).hide()
    }
  }

  toggleVocValues(isSelect) {
    if (isSelect) {
      $(this.vocValuesTarget).show()
    } else {
      $(this.vocValuesTarget).hide()
    }
  }
}
