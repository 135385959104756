
import { Controller } from "stimulus"
import StimulusReflex from 'stimulus_reflex'
import $ from "jquery"

export default class extends Controller {
  static targets = ["frmo"]

  connect() {
    StimulusReflex.register(this)
  }

  doFrmoDetail(e) {
    const vocFrmoId = this.frmoTarget.value
    this.stimulate('OrganizationData#detail', vocFrmoId)
  }
}
